import * as React from "react"
import * as styles from '../styles/home.module.css'
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Naslovnica" />
    
    <p className={styles.marginTop}><strong>Kad bi vas netko upitao kada idete u ljekarnu, odgovor bi vjerojatno bio: kad sam bolesna. I to je činjenica, kad ste potpuno zdravi nemate naviku ići u ljekarnu i provjeravati koje nove proizvode imaju, osim ako ne morate dignuti lijek pa usput u izlogu vidite novi dodatak za imunitet.</strong></p>
    <p>No, jeste li kad razmišljali o tome da odete u ljekarnu kad vam nestane dermatološke kozmetike? U ljekarni uvijek možete naći <strong>izvrsnu i provjerenu kozmetiku</strong> koja sigurno neće naštetiti vašoj koži lica i tijela, za razliku od preparata koje kupujete u drogerijama. Isto tako, ne bi bilo loše da, ako primjerice imate problematičnu kožu, <strong>za savjet oko dermokozmetike</strong> i krema za lice pitate farmaceutkinju. Osim što je žena, pa ima iskustva s različitom šminkom, farmaceutkinja je i stručna osoba koja vam može preporučiti preparate za <strong>njegu svakog tipa kože.</strong> Ako ju tražite za savjet oko lijeka, zašto ju ne biste pitali i za savjet oko dermokozmetike?</p>
    <p>Probleme s kožom najčešće imaju tinejdžeri, koji prolaze kroz hormonalne promjene, a ponekad se ti <strong>problemi nastavljaju i u odrasloj dobi.</strong> Druga skupina koju pogađaju ovi problemi su <strong>žene u menopauzi</strong>, a razlog je, vjerovali ili ne – isti!</p>
    
    <div className={styles.videoWrapper}>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/G-X-AGDGXNI" title="Vox populi: kupujete li kozmetiku u ljekarni?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    <div className={styles.margin}>

      <div className={styles.wrapper}>

        <div className={styles.cardBox}>
          <h2>Što je menopauza?</h2>
        </div>

        <div className={styles.cardImage}>
        <StaticImage
            src="../images/sto-je-menopauza.jpg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="menopauza"
          />
        </div>

      </div>

    </div>

    <p>Menopauza ili prestanak menstruacijskih krvarenja je naziv za završetak reprodukcijskog ciklusa žene, a obično se javlja <strong>između 45. i 55. godine života</strong>. No, to nije isključivo Menopauza može nastupiti i između 40. – 60. godine, ali i ranije kao posljedica operacije ili bolesti. </p>
    <p>Pojam menopauza često se rabi za opis cijelog razdoblja koje prethodi, a naziva se predmenopauza i za ono koje slijedi po prestanku menstruacijskih krvarenja, odnosno postmenopauza. Pojam <strong>perimenopauza ili prijelazno perimenopauza ili prijelazno</strong> razdobljerazdoblje označava kratko razdoblje prije same menopauze, zatim menopauzu i kratko razdoblje nakon zadnje menstruacije.</p>
    <p><strong>Uzrok menopauze</strong> je postupno iscrpljivanje funkcije jajnika, što naposljetku dovodi do <strong>potpunog prestanka sinteze</strong> i ženskih, spolnih hormona (estrogena i progesterona), ali i muških spolnih hormona androgena (DHEAS i testosterona).</p>

    <div className={styles.margin}>

      <div className={styles.wrapper}>

        <div className={styles.cardBox}>
          <h2>Mitovi o menopauzi</h2>
        </div>

        <div className={styles.cardImage}>
        <StaticImage
            src="../images/mitovi-o-menopauzi_2.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="menopauza"
          />
        </div>

      </div>

    </div>

    <p>Mnoge žene imaju krive predodžbe o prirodnim procesima i učincima menopauze. Žene u zapadnim zemljama imaju određeni <strong>strah od menopauze</strong> i doživljavaju ga kao kraj jedne ere. No, u nekim drugim svjetskim kulturama ne postoji strah od približavanja menopauzi, oni vjeruju da simptomi zapravo ne postoje i menopauzu dočekuju kao prelazak žene u <strong>mudrije i sigurnije životno doba.</strong></p>

    <div className={styles.margin}>

      <div className={styles.wrapper}>

        <div className={styles.cardBox}>
          <h2>Simptomi menopauze</h2>
        </div>

        <div className={styles.cardImage}>
        <StaticImage
            src="../images/simptomi-menopauze_1.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="menopauza"
          />
        </div>

      </div>

    </div>

    <p>Što će jedna žena doživjeti tijekom menopauze ovisi o mnogo čimbenika, od nasljednih osobina, preko očekivanja, kulturne sredine do vlastitog vrednovanja i prehrane. Ipak, većina žena u suvremenoj kulturi proživljava <strong>nelagodu i neke problematične simptome</strong> u menopauzi, koji sami po sebi nisu opasni, ali znaju biti izrazito neugodni za žene.</p>

    <p>
      <i>
      U zadnje vrijeme znanje žena o menopauzi sve je veće, a isto tako i interes za pronalaženjem načina kako si u navedenom razdoblju života pomoći. Iako je menopauza normalan slijed u životu žene, često bude popraćena simptomima i tegobama koje <strong>značajno narušavaju kvalitetu života</strong>. Stoga i ne čudi što žene odlaze po savjete liječnicima različitih subspecijalnosti. Bez obzira na simptome, danas imamo ne samo znanje već i mogućnosti <strong>djelovati preventivno</strong>, pa stoga i žene aktivnije potičemo na preglede i poduzimanje preventivnih mjera. Postoji cijeli niz stručnjaka, prvenstveno su to ginekolozi, ali i liječnici obiteljske medicine, internisti, endokrinolozi, psihijatri koji se mogu uključiti <strong>savjetima i propisivanjem terapije.</strong> Naravno, osim liječničkog, savjet je dobrodošao i od drugih stručnjaka poput <strong>nutricionista i kineziologa.</strong>
      </i>
    </p>

    <p className={styles.primaryText}>doc. dr. sc. Maja Cigrovski Berković, specijalistica endokrinologije.</p>

    <p><strong>Najčešći simptomi menopauze su:</strong></p>

    <h3><span className={styles.textWhite}>Valovi vrućine</span></h3>
    <p>Hormonalne promjene koje dolaze u menopauzi mogu uzrokovati valove vrućine. Javljaju se vrlo često, ali njihov intenzitet može varirati od žene do žene. Karakterizira ih <strong>iznenadna navala vrućine</strong> praćena znojenjem i osjećajem zimice te može trajati par sekundi do par minuta. Najčešće se javljaju tijekom noći i sprječavaju da se naspavate, no mogu se javiti i tijekom dana najčešće <strong>potaknuti emocijama.</strong></p>

    <h3><span className={styles.textWhite}>Povećanje tjelesne težine</span></h3>
    <p>Tijekom menopauze dolazi do promjene u raspodjeli masnoća u tijelu i u većini slučajeva masnoće se nakupljaju u gornjem dijelu tijela: trbuhu i grudima. Višak kilograma je česta pojava, <strong>no možemo ju držati pod kontrolom</strong></p>

    <p>
      <i>
      Pravilan odabir hrane može imati brojne pozitivne učinke na hormonsku ravnotežu i brojne druge segmente zdravlja žene u razdoblju menopauze. Odabirom zdravog životnog stila što uključuje <strong>pravilne prehrambene navike, redovitu tjelesnu, ali i mentalnu aktivnost</strong> te obilje sna moguće je zadržati ženstveno tijelo unatoč menopauzi.
      </i>
    </p>

    <p className={styles.primaryText}>prof. dr. sc. Darija Vranešić Bender, klinička nutricionistkinja</p>

    <h3><span className={styles.textWhite}>Nesanica</span></h3>
    <p>U periodu menopauze, veliki broj žena pati od poremećaja spavanja. Bilo da se radi o nesanici, snu isprekidanom valovima vrućine ili napadima stresa, ovaj poremećaj <strong>značajno narušava kvalitetu života</strong> i dovodi do stanja kroničnog umora, razdražljivosti, a ponekad i depresije i ostalih težih simptoma.</p>


    <div className={styles.margin}>

      <div className={styles.wrapper}>

        <div className={styles.cardBox}>
          <h2>Kako se boriti sa simptomima menopauze?</h2>
        </div>

        <div className={styles.cardImage}>
        <StaticImage
            src="../images/kako-se-boriti-sa-simptomima-menopauze_1.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="menopauza"
          />
        </div>

      </div>

    </div>

    <p>Njega i zdrav životni stil dobra su priprema za promjene koje donosi menopauza. Zahvaljujući dobroj pripremi za menopauzu, uslijed hormonskih promjena <strong>neće trpjeti zdravlje, raspoloženje i izgled.</strong> Dobro pripremljene žene znaju da u menopauzi <strong>nema razloga za paniku,</strong> ali i da treba poduzeti sve potrebno za održanje zdravlja i vitalnosti tijela.</p>

    <p>Ako se spomenutim simptomima pridruže otežano mokrenje i bolni spolni odnosi (zbog nedovoljnog djelovanja estrogena na sluznicu) tada, savjetuje <strong>Maja Cigrovski Berković, dr.med.spec. endokrinolog</strong>, treba obaviti pretrage kako bi se <strong>odredila eventualna terapija</strong> te dodaje: </p>

    <p>
      <i>
        “Osim izostanka menstruacije i spomenutih simptoma, menopauza se može utvrditi i laboratorijskim nalazima u kojima se, pa i kod mlađih žena, vide visoke razine FSH ili folikulostimulirajućeg hormona kojeg luči hipofiza i nizak estradiol. Dijagnosticiranje menopauze važno je i zbog promjena u tijelu, osobito u kostima, srcu i krvožilnom sustavu.”
      </i>  
    </p>


    <div className={styles.margin}>

      <div className={styles.wrapper}>

        <div className={styles.cardBox}>
          <h2>Koža umenopauzi</h2>
        </div>

        <div className={styles.cardImage}>
        <StaticImage
            src="../images/koza-u-menopauzi_1.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="menopauza"
          />
        </div>

      </div>

    </div>

    <p>Najizraženiji, svima uočljiv, znak menopauze je <strong>ubrzano starenje kože.</strong> Koža postaje suha, potkožno masno tkivo se spušta prema donjem dijelu čeljusti, a mišići lica se opuštaju. Kao posljedica toga, <strong>crte lice postaju manje izražene</strong>, a koža lica i vrata opuštena i obješena.</p>

    <p>Na starenje utječu <strong>genetski čimbenici</strong>, ali i UV zračenje, životne navike te razne promjene kao što je menopauza. Starenje zahvaća više organskih sustava pa tako i kožu kao naš najveći organ. Uslijed <strong>smanjenja razine estrogena</strong> dolazi do preraspodjele potkožnog masnog tkiva što rezultira <strong>gubitkom volumena kože lica</strong>, osobito na području oko očiju, usnica i obraza. U dermisu se smanjuje količina elastinskih vlakana pa dolazi do smanjenja elastičnosti kože.</p>


    <StaticImage
      src="../images/infografika_vichy.png"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="infografika_vichy"
      style={{ marginBottom: `1.45rem` }}
    />

    <p>Velike promjene nastaju i na površini kože, u epidermisu: koža postaje tanja, manje elastična, ovješena, <strong>poprima sivkasti ton</strong>, javljaju se tamne mrlje i kapilare. <strong>Usporeno stvaranje lipida</strong>, dovodi do suhoće kože, a koža se i sve teže obnavlja. Iz tog razloga, potrebno je voditi posebnu brigu o koži, jer u menopauzi Vaša koža traži osobitu pažnju. Važno je izabrati prikladne proizvode koji joj pružaju redovitu i intenzivnu njegu koja će ju <strong>nahraniti, učiniti vitalnom te joj podariti mladenački izgled.</strong></p>

    <p>Menopauza sa sobom nosi <strong>razne promjene.</strong> Na neke se treba naviknuti, a protiv nekih se treba boriti. Sve je to dio sazrijevanja i zdravog starenja. Ostaje pitanje što žene u zrelijim godinama mogu učiniti kada se počnu pojavljivati problemi s licem uzrokovani hormonalnim promjenama? Tijekom, <strong>ali i nakon menopauze</strong>, posebnu pozornost treba obratiti na <srtong>pravilnu rutinu kojom se njeguje lice</srtong> jer samo je tako moguće poboljšati izgled kože. Najvažnija je upornost, odnosno ustrajanje u rutini njege lica i redovito provođenje iste.</p>

    <p>Na promjene koje se uslijed menopauze javljaju na koži, nažalost, ne možete utjecati, ali itekako možete izabrati kako ćete se prema svojoj koži odnositi. Iz Vichy-ja zato stiže poruka: <strong>pedesete mogu biti prelijepo razdoblje u životu svake žene</strong>, a menopauza je normalan nastavak života, u kojem i dalje trebate biti zdrave, njegovane, sretne i nasmijane!</p>

    <p>
      <i>
      „Koža se može puno ranije početi pripremati na menopauzu pojačanim njegama u vidu stimulacije kolagena i čuvanja dubokih struktura potkožja tako da se kroz taj period prođe manje vidno i bez drastičnih promjena. Svaki vid njege kože od <a className={styles.primaryText} href="https://www.vichy.hr/le-vichy-mag/nema-pauze-u-menopauzi/vmag75957.aspx" target="_blank" rel="noopener noreferrer">neinvazivnih tretmana</a> do raznih drugih mogućnosti stimulacije obnove kože je dobrodošao i prije 40-te godine, a nakon toga je obavezan ako želimo taj period prođe sa što manje posljedica. Ako žene prije menopauze ne osjete potrebu za anti-age tretmanima, jer imaju inače dobru genetsku kvalitetu kože, menopauza ih obično potakne da naprave iskorak barem u vidu <a className={styles.primaryText} href="https://www.vichy.hr/le-vichy-mag/nema-pauze-u-menopauzi/vmag75957.aspx" target="_blank" rel="noopener noreferrer">pojačane njege</a> i traže savjet stručnjaka za mogućim tretmanima kako bi popravile izgled lica“
      </i>
    </p>

    <p className={styles.primaryText}>dr. Jelena Jakić, specijalistica dermatovenerologije.</p>

    <p>Osim što su Laboratoriji Vichy nakon 14 godina istraživanja i 13 patenata razvili prilagođenu anti-age rutinu za njegu kože koja odgovara na posebne potrebe kože u menopauzi – <a className={styles.primaryText} href="https://www.vichy.hr/neovadiol/asortiman/r2096.aspx" target="_blank" rel="noopener noreferrer">Neovadiol liniju proizvoda</a>, a napravljeni su i daljnji koraci u razbijanju svih mitova o ovom poglavlju u životu žene.</p>
    
    <div className={styles.margin}>

      <div className={styles.wrapper}>

        <div className={styles.cardBox}>
          <h2>Neovadiol</h2>
        </div>

        <div className={styles.cardImage}>
        <StaticImage
            src="../images/neovadiol-krema.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="menopauza"
          />
        </div>

      </div>

      </div>

      <p>Uz Vichy Neovadiol liniju moguće je <strong>”oživjeti” kožu i postići blistaviji, ljepši ten</strong> u ovom specifičnom periodu života. Neovadiol linija predstavlja revolucionarno anti-age rješenje koje ublažava znakove menopauze na Vašoj koži vraćajući joj izgled i osjećaj mladolikog tena. Vaša je koža u trenu svježa i blistava, čvršća i punija te preoblikovana. Zahvaljujući otkrićima 14 godina istraživanja o utjecaju menopauze na starenje kože, <strong>13 patenata i 4 snažna aktivna sastojka</strong> Vichy Neovadiol već više od 15 godina odgovara na posebne potrebe kože koja se mijenja uvodeći anti-age proizvode koji su rezultat intenzivnog znanstvenog istraživanja i ciljano djeluju na probleme poput gubitka gustoće i volumena kože, suhoće i neujednačenosti tena kod žena u menopauzi.</p>

      <h3><span className={styles.textWhite}>NEOVADIOL DNEVNA NJEGA</span></h3>

      <p>Formula nadahnuta povoljnim učinkom hormona na mladolikost kože, sadrži odabran kompleks aktivnih sastojaka koji je toliko snažan da djeluje na markere mladolikosti pogođene menopauzom. Koža je vidljivo mlađa u svakom pogledu. PRO-XYLANE obnavlja gustoću, volumen i čvrstoću kože te pomaže u porastu razine kolagena. HEPES pomaže pri zaglađivanju kože, ističe njenu prirodnu svježinu i sjaj te izjednačava ten. GLICERIN je sastojak koji zadržava vodu u vanjskim slojevima i nudi osjećaj ugode i neprekidnu hidrataciju kako bi nadomjestio vlagu koju zahtijeva koža u menopauzi. HEDIONE pomaže nadomjestiti manjak lipida kao posljedicu menopauze te obnoviti prirodne masnoće dajući koži elastičnost. Dostupna je kao dnevna krema za normalnu do mješovitu kožu te za suhu kožu.</p>

      <StaticImage
      src="../images/2.png"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="vichy"
      style={{ marginBottom: `1.45rem` }}
    />

    <h3><span className={styles.textWhite}>NEOVADIOL NOĆNA NJEGA</span></h3>

    <p>Iznimno svježa noćna formula s osjećajem hlađenja nakon nanošenja za dugotrajan osjećaj ugode na koži. Kao i dnevna njega sadrži odabran kompleks aktivnih sastojaka: PRO-XYLANE, HEPES, GLICERIN i HEDIONE, a dodatno je obogaćena <strong>sastojcima za ublažavanje znakova menopauze</strong> i tijekom noći. EPÉRULIN umiruje kožu i smanjuje crvenilo. KOFEIN kao stimulans dekongestivnog djelovanja bori se protiv znakova umora. AIRLICIUM je sastojak kojeg 99% čini zrak i ima veliku <strong>sposobnost upijanja sebuma i znoja.</strong> AQUAKEEP polimeri mogu apsorbirati veliku količinu vode koja se oslobađa u dodiru s kožom. Tekstura se na koži tijekom nanošenja pretvara u vodu i koži daje <strog>dubinski osjećaj svježine.</strog></p>

    <h3><span className={styles.textWhite}>NEOVADIOL PHYTOSCULPT</span></h3>

    <p>Njega koja učvršćuje kožu na vratu i preoblikuje konture lica. Aktivni sastojak FITOSTEROL biljni ekstrakt iz lista divlje paprati <strong>preoblikuje konture lica i učvršćuje područje vrata.</strong> Uz kombinaciju sastojaka PRO-XYLANE i HIJALURONSKE KISELINE poboljšava tonus i gustoću kože vrata i dekoltea – preoblikuje ga i podiže. Posebna tekstura se u dodiru s kožom na 32°C upija u kožu i omogućava <srtong>idealnu masažu</srtong> vrata i kontura lica. Kada se ohladi, tekstura se pretvara u sloj koji podiže i učvršćuje kožu.</p>

    <StaticImage
      src="../images/3.png"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="vichy"
      style={{ marginBottom: `1.45rem` }}
    />

    <h3><span className={styles.textWhite}>NEOVADIOL SERUM</span></h3>

    <p>Intenzivni tretman za kožu u menopauzi s aktivnim sastojcima: PRO-XYLANE, HEPES, GLICERIN i HEDIONE u visokoj koncentraciji i s naprednim učinkom hidratacije, snažno djeluje na <strong>ublažavanje utjecaja menopauze na kožu.</strong> Zahvaljujući dodatnim sastojcima i naprednoj tehnologiji, djeluje i na blistavost, svježinu i ujednačenost tena.</p>

    <StaticImage
      src="../images/1.png"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="vichy"
      style={{ marginBottom: `1.45rem` }}
    />

    <h3><span className={styles.textWhite}>NEOVADIOL NJEGA ZA PODRUČJE OKO OČIJU I USANA</span></h3>

    <p>Za žene u menopauzi koje žele djelovati protiv učinaka sporije hormonalne aktivnosti na području oko očiju i usana: gubitka gustoće i opuštanje. Jedinstveni spoj aktivnih sastojaka <strong>preoblikuje konture usana i podiže konture očiju.</strong> PROTEIC GF potiče čimbenike rasta (protein neophodan za strukturiranu rekonstrukciju tkiva) i PRO-XYLANE koji jača potporna tkiva gdje se nalaze faktori rasta.* Za jačanje djelovanja na području kontura usana i očiju sastojci odgovorni za <strong>rekonstrukciju tkiva</strong> kombinirani su s EPÉRULINE-om, aktivnim sastojkom iz jednog od najotpornijih drva amazonske šume. Ovaj je aktivni sastojak ispitan in vitro i dokazana je učinkovitost na sprečavanje pigmentacije i vazodilataciju kože što <strong>ublažava podočnjake i vrećice ispod očiju.</strong></p>

    <p>*Test in vitro.</p>

  </Layout>
)

export default IndexPage
